<template>
  <div>
    <div v-if="preloader" class="loader-center">
      <div class="atom-spinner">
        <div class="spinner-inner">
          <div class="spinner-line"></div>
          <div class="spinner-line"></div>
          <div class="spinner-line"></div>
          <div class="spinner-circle">
            <img
              src="../assets/img/logo-01.png"
              class="logostyle"
              alt="TuroBuddy Logo"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="outer" style="background: white" v-else>
      <div class="overflow-hidden">
        <v-container
          fluid
          style="padding-bottom: 0px !important"
          v-if="this.selectedPackages.length > 0"
        >
          <br /><br /><br /><br />
          <v-row justify="space-around">
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-sheet elevation="0" class="py-4 px-1">
                <v-chip-group single v-model="selection">
                  <v-chip filter outlined @click="all"> All </v-chip>
                  <v-chip filter outlined @click="general"> General </v-chip>
                  <v-chip filter outlined @click="ethnic"> Ethnic </v-chip>
                  <v-divider vertical style="margin-right: 10px"></v-divider>
                  <v-chip filter outlined> Air Conditioning </v-chip>
                  <v-chip filter outlined> Kitchen </v-chip>
                  <v-chip filter outlined> Wifi </v-chip>
                  <v-chip filter outlined> Washing Machine </v-chip>
                  <v-chip
                    filter
                    outlined
                    @click="filterPackageBySelection('Air-condition')"
                  >
                    Iron
                  </v-chip>
                  <v-chip filter outlined>Dryer</v-chip>
                  <v-divider vertical style="margin-right: 10px"></v-divider>
                  <v-chip outlined @click="filterPackages">
                    <v-icon>filter_alt</v-icon>Filters</v-chip
                  >
                </v-chip-group>
              </v-sheet>
            </v-col>
          </v-row>
          <div
            v-if="allPackages.length < 1"
            style="margin-top: 15px; margin-bottom: 15px"
          >
            <img
              style="width: 30%; margin: auto; display: flex"
              src="../assets/img/noresult.jpg"
            />
            <div v-if="filterEmpty" style="text-align: center">
              <h2 style="font-weight: 500; margin-bottom: 15px">
                Oops!! We Don`t Have Package With Your Choice Right Now.
              </h2>
              <p>
                We are working with our partners on getting the packages as per
                your choice.
              </p>
            </div>
            <div v-else style="text-align: center">
              <h2 style="font-weight: 500; margin-bottom: 15px">
                We Are Coming Soon To Your Location.
              </h2>
              <p>
                However, you can check out our other exciting packages in other
                locations to make your holiday memorable.
              </p>
            </div>
          </div>
          <v-divider></v-divider>
          <v-row
            v-if="allPackages.length > 0"
            style="padding: 10px; margin-top: 15px; margin-bottom: 15px"
          >
            <v-col
              lg="3"
              md="3"
              sm="4"
              xs="12"
              v-for="(item, index) in allPackages"
              v-bind:key="index"
              style="padding: 5px"
              @click="selectedPackageDetails(item)"
            >
              <v-card
                style="background: transparent !important; margin: 10px"
                tile
                elevation="0"
                ripple
              >
                <v-carousel
                  show-arrows-on-hover
                  hide-delimiter-background
                  hide-delimiter
                  delimiter-icon="mdi-minus"
                  style="border-radius: 20px"
                  height="210px"
                >
                  <v-carousel-item
                    eager
                    v-for="(image, index) in item.assets"
                    v-bind:key="index"
                    reverse-transition="fade-transition"
                    transition="fade-transition"
                  >
                    <v-img
                      :src="image.asset_url"
                      :lazy-src="image.asset_url"
                      eager
                      width="100%"
                      height="100%"
                      aspect-ratio="1:4"
                    />
                  </v-carousel-item>
                </v-carousel>
                <div style="padding: 2px !important">
                  <h2
                    style="
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      font-size: 15px !important;
                      text-transform: none;
                      margin-top: 10px;
                    "
                  >
                    {{ item.package_title }}
                  </h2>
                  <p>{{ getAvailabilityDates(dates) }}</p>
                  <p>
                    <b>US${{ item.price }}</b> / night
                  </p>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <div v-else>
          <br /><br /><br /><br />
          <div
            style="
              margin: 25px;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <img
              style="width: 30%"
              src="../assets/img/noresult.jpg"
              alt="No Packages Found"
            />
          </div>
          <div v-if="filterEmpty" style="text-align: center">
            <h2 style="font-weight: 500; margin-bottom: 15px">
              Oops!! We Don`t Have Package With Your Choice Right Now.
            </h2>
            <p>
              We are working with our partners on getting the packages as per
              your choice.
            </p>
          </div>
          <div v-else style="text-align: center">
            <h2 style="font-weight: 500; margin-bottom: 15px">
              We Are Coming Soon To Your Location.
            </h2>
            <p>
              However, you can check out our other exciting packages in other
              locations to make your holiday memorable.
            </p>
          </div>
          <br />
        </div>

        <v-btn
          v-scroll="onScroll"
          v-show="fab"
          fab
          dark
          fixed
          bottom
          right
          color="yellow darken-1"
          @click="toTop"
        >
          <v-icon>keyboard_arrow_up</v-icon>
        </v-btn>
        <tb-footer></tb-footer>
      </div>
    </div>
  </div>
</template>
<script>
import Footer from '../components/FooterEl.vue';
import '../assets/css/style.css';
import { mapState } from 'vuex';
import _ from 'lodash';
import msgHelper from '../../utils/msg-helper';
import EventBus from '../../utils/EventBus';

export default {
  components: {
    'tb-footer': Footer,
  },
  data() {
    return {
      selection: 'all',
      selectionOpts: [
        'all',
        'general',
        'ethnic',
        'Air-condition',
        'Kitchen',
        'Wi-fi',
        'Washing-Machine',
        'Iron',
        'Dryer',
        'filter',
      ],
      selectedPackages: [],
      packageDetails: [],
      ethnicSwitch: false,
      name: '',

      // Property type
      house: '',
      guesthouse: '',
      bunglow: '',
      chalet: '',
      guestsuite: '',
      cottage: '',
      hotel: '',
      resort: '',
      townhouse: '',
      flat: '',
      boutiquehotel: '',
      cabin: '',
      hostel: '',
      loft: '',
      villa: '',
      // Property type
      // Facilities
      freeparking: '',
      pool: '',
      gym: '',
      hottub: '',
      isPackageAvailable: true,
      // Facilities
      // Amenities
      kitchen: '',
      aircondition: '',
      privatewashroom: '',
      balcony: '',
      dryer: '',
      iron: '',
      cot: '',
      heating: '',
      washingmachine: '',
      wifi: '',
      indoorfireplace: '',
      hairdryer: '',
      tv: '',
      highchair: '',
      waterfront: '',
      beachfront: '',

      // Amenities
      country: '',
      overlay: false,
      selected: true,
      generalSelected: false,
      currentTitle: '',
      value: 1,
      fab: false,
      preloader: false,
      ethnicFlag: true,
      list: undefined,
      storedPackages: [],
      date: null,
      trip: {
        name: '',
        location: null,
        start: null,
        end: null,
      },
      selectedcity: '',
      privateRoom: false,
      date2: new Date(Date.now() - new Date().getTimezoneOffset() * 70000)
        .toISOString()
        .substr(0, 10),
      searchFailed: false,
      searchRunning: false,
      dates: [],
      filterBy: '',
      filterByOpts: false,
      filteredData: null,
      filterEmpty: false,
    };
  },
  computed: {
    ...mapState(['packages', 'packageId']),
    availWidth() {
      return window.screen.width;
    },
    packages: {
      get() {
        return this.$store.state.packages;
      },
    },
    allPackages: {
      get() {
        const packages = this.packages;
        if (this.ethnicFlag && !this.generalSelected) {
          return packages.filter((pkg) => pkg.is_ethnic);
        } else if (!this.ethnicFlag && this.generalSelected) {
          return packages.filter((pkg) => !pkg.is_ethnic);
        } else if (this.filterBy !== '') {
          return _.without(
            _.map(this.packages, (pkg) => {
              if (pkg.amenities.includes(this.filterBy)) {
                return pkg;
              }
            }),
            undefined
          );
        } else if (this.filterByOpts) {
          return this.getPackagesByFilterOpts(packages);
        } else {
          return this.packages;
        }
      },
      set(val) {
        return val;
      },
    },
  },
  created() {
    this.init();
  },
  methods: {
    getPackagesByFilterOpts(packages) {
      const packageCol = [];
      const packagesLen = packages.length - 1;
      let index = 0;
      do {
        let isAmenitiesInc = false;
        let isFacilitiesInc = false;
        let isPropertyTypeInc = false;

        for (let i of this.filteredData.amenities) {
          const isInc = packages[index]['amenities'].includes(i);
          if (isInc) {
            isAmenitiesInc = true;
            break;
          } else {
            continue;
          }
        }

        for (let i of this.filteredData.facilities) {
          const isInc = packages[index]['facilities'].includes(i);
          if (isInc) {
            isFacilitiesInc = true;
            break;
          } else {
            continue;
          }
        }

        for (let i of this.filteredData.amenities) {
          const propertyType = packages[index]['property'][0].property_type;
          if (propertyType === i) {
            isPropertyTypeInc = true;
            break;
          } else {
            continue;
          }
        }

        if (isAmenitiesInc || isFacilitiesInc || isPropertyTypeInc) {
          packageCol.push(packages[index]);
        }
        index += 1;
      } while (index < packagesLen);
      return packageCol;
    },
    filterPackageBySelection(filterBy) {
      this.filterBy = filterBy;
    },
    getAvailabilityDates(dates) {
      const formattedDates = _.map(dates, (date) =>
        this.$moment(date).format('Do MMMM, YYYY')
      );
      return _.join(formattedDates, ' - ');
    },
    init() {
      if (this.$router.currentRoute && this.$router.currentRoute.params) {
        const countryName = this.$router.currentRoute.params.country_name;
        this.country_name = countryName;
        document.title = `TuroBuddy | ${this.country_name} - Holiday Rentals & Places to Stay`;
        if (Object.keys(this.$router.currentRoute.query).length > 0) {
          const searchedOpts = this.$router.currentRoute.query;
          console.log(searchedOpts, this.packages);
          this.searchRunning = Number(searchedOpts.search) === 1 || false;
          this.searchFailed = Number(searchedOpts.search) === 2 || false;
          if (this.packages.length === 0) {
            this.isPackageAvailable = false;
          } else {
            this.ethnicFlag = false;
            this.generalSelected = false;
            this.selectedPackages = this.packages;
            // Selecting from available package
            // this.packageDetails = this.selectedPackages[0];
            // const checkIn = this.packageDetails.check_in_date;
            // this.checkDate = this.$moment.unix(checkIn).format('YYYY-MM-DD');
            // const checkOut = this.packageDetails.check_out_date;
            // this.checkOutDate = this.$moment
            //   .unix(checkOut)
            //   .format('YYYY-MM-DD');
            // this.dates = [this.checkDate, this.checkOutDate];
            // this.$store.commit('setPackageId', this.packageDetails._id);
          }
        } else {
          this.getPackagesByCountryName(countryName);
        }
      }
    },
    async getPackagesByCountryName(country_name) {
      this.preloader = true;
      this.isPackageAvailable = true;
      try {
        const res = await this.$http.post('fetchPackageByCountry', {
          country: country_name,
        });
        this.selectedPackages = res.data.data;
        this.$store.commit('setPackages', this.selectedPackages);
        if (this.selectedPackages.length === 0) {
          this.isPackageAvailable = false;
        } else {
          this.ethnicFlag = false;
          this.generalSelected = false;
          // Selecting from available package
          // this.packageDetails = this.selectedPackages[0];
          // this.$store.commit('setPackageId', this.packageDetails._id);
          // Dates
          // const checkIn = this.packageDetails.check_in_date;
          // this.checkDate = this.$moment.unix(checkIn).format('YYYY-MM-DD');
          // const checkOut = this.packageDetails.check_out_date;
          // this.checkOutDate = this.$moment.unix(checkOut).format('YYYY-MM-DD');
          // this.dates = [this.checkDate, this.checkOutDate];
        }
      } catch {
        msgHelper.showToast('error', 'We Are Coming Soon To Your Location');
        this.isPackageAvailable = false;
      }
      this.preloader = false;
    },
    getCity() {
      this.list = this.storedPackages;
      const cities = [];
      let i = 0;
      while (i < this.list.length) {
        const search = new RegExp(this.selectedcity.trim().toLowerCase(), 'g');
        if (this.list[i].package_title.trim().toLowerCase().match(search)) {
          cities.push(this.list[i]);
        }
        i++;
      }
      this.list = cities;
      this.storedCPackages = this.storedPackages;
    },
    ethnic() {
      this.ethnicFlag = true;
      this.generalSelected = false;
      this.selected = true;
      this.filterByOpts = false;
      this.filterBy = '';
      this.filterEmpty = false;
      if (this.allPackages.length > 0) {
        // this.packageDetails = this.allPackages[0];
        const checkIn = this.packageDetails.check_in_date;
        this.checkDate = this.$moment.unix(checkIn).format('YYYY-MM-DD');
        const checkOut = this.packageDetails.check_out_date;
        this.checkOutDate = this.$moment.unix(checkOut).format('YYYY-MM-DD');
        this.dates = [this.checkDate, this.checkOutDate];
        // this.$store.commit('setPackageId', this.packageDetails._id);
      } else {
        this.isPackageAvailable = false;
      }
    },
    general() {
      this.ethnicFlag = false;
      this.generalSelected = true;
      this.selected = false;
      this.filterByOpts = false;
      this.filterBy = '';
      this.filterEmpty = false;

      if (this.allPackages.length > 0) {
        // this.packageDetails = this.allPackages[0];
        const checkIn = this.packageDetails.check_in_date;
        this.checkDate = this.$moment.unix(checkIn).format('YYYY-MM-DD');
        const checkOut = this.packageDetails.check_out_date;
        this.checkOutDate = this.$moment.unix(checkOut).format('YYYY-MM-DD');
        this.dates = [this.checkDate, this.checkOutDate];
        // this.$store.commit('setPackageId', this.packageDetails._id);
      }
    },
    all() {
      this.ethnicFlag = false;
      this.generalSelected = false;
      this.selected = true;
      this.filterByOpts = false;
      this.filterBy = '';
      this.filterEmpty = false;
    },
    navigateToPackageDetails() {
      const packageId = this.packageId;
      this.$router.push({
        name: 'Package Details',
        params: {
          packageId,
        },
      });
    },
    selectedPackageDetails(pkg) {
      this.packageDetails = pkg;
      // const checkIn = this.packageDetails.check_in_date;
      // const checkOut = this.packageDetails.check_out_date;
      // this.checkDate = this.$moment.unix(checkIn).format('YYYY-MM-DD');
      // this.checkOutDate = this.$moment.unix(checkOut).format('YYYY-MM-DD');
      // this.dates = [this.checkDate, this.checkOutDate];
      this.$store.commit('setPackageId', pkg._id);
      this.$router.push({
        name: 'Package Details',
        params: {
          packageId: pkg._id,
        },
      });
      // if (this.availWidth <= 600) {
      //   this.navigateToPackageDetails();
      // }
    },
    onScroll(e) {
      if (typeof window === 'undefined') return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    filterPackages() {
      this.filterBy = '';
      EventBus.$emit('openFilter', true);
      EventBus.$on('updateFilter', (ret) => {
        if (
          ret.amenities.length > 0 ||
          ret.facilities.length > 0 ||
          ret.propertyType.length > 0
        ) {
          this.filteredData = ret;
          this.filterByOpts = true;
          this.filterEmpty = false;
        } else {
          this.filterEmpty = true;
          this.filterByOpts = false;
        }
        EventBus.$off('updateFilter');
      });
    },
  },
};
</script>
